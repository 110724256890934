<template>
    <div id="home" class="page-wrapper page-home">
        <!-- Hero -->
        <section id="hero" class="hero site-max-width">
            <div class="inner">
                <h1 class="big-title title" data-inview="fadeInUp" data-delay="1000">
                    <span v-html="contentIsLoaded ? content.hero.titre : '&nbsp;'"></span>
                </h1>
                <div class="grid-images-dynamic" hero-parallax-left>
                    <img
                        class="size"
                        :src="contentIsLoaded ? content.hero.image1.url : '/static-imgs/quartier-rosemont.jpg'"
                        :alt="contentIsLoaded ? content.hero.image1.titre : ''"
                    />

                    <img
                        class="main"
                        :src="contentIsLoaded ? content.hero.image1.url : '/static-imgs/quartier-rosemont.jpg'"
                        :alt="contentIsLoaded ? content.hero.image1.titre : ''"
                        hero-img-left
                    />

                    <img
                        class="secondary-top"
                        :src="contentIsLoaded ? content.hero.image2.url : ''"
                        :alt="contentIsLoaded ? content.hero.image2.titre : ''"
                        hero-img-top
                    />
                    <img
                        class="secondary-bottom"
                        :src="contentIsLoaded ? content.hero.image3.url : ''"
                        :alt="contentIsLoaded ? content.hero.image3.titre : ''"
                        hero-img-bottom
                    />

                    <a
                        href="#projet"
                        class="scroll-down"
                        data-inview="fadeInUp"
                        data-delay="1200"
                        @click.prevent="smoothScrollTo('#projet')"
                    >
                        <svg
                            height="65"
                            viewBox="0 0 43 89"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.5739 85.5268L20.5739 -1.96055e-06L22.4261 -1.79863e-06L22.4261 85.5268L41.7031 65.9109L43 67.2244L21.5 89L-5.72571e-06 67.2244L1.2046 65.9109L20.5739 85.5268Z"
                                fill="black"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        </section>

        <!-- Projet -->
        <section id="projet" class="projet site-max-width">
            <div class="mobile-imgs hide-on-desktop">
                <img
                    :src="contentIsLoaded ? content.hero.image2m.url : ''"
                    :alt="contentIsLoaded ? content.hero.image2m.titre : ''"
                    hero-img-right-mobile
                />
                <SealDurable class="mobile-seal" condo-rotate-mobile />
            </div>
            <div class="inner">
                <h3 class="top-label label" data-inview="fadeInUp" data-delay="200">
                    {{ contentIsLoaded ? content.hero.sousTitre : '&nbsp;' }}
                </h3>
                <p class="big-text lefty-bloc-text" data-inview="fadeInUp" data-delay="400">
                    {{ contentIsLoaded ? content.hero.texte : '&nbsp;' }}
                </p>
                <router-link :to="contentIsLoaded && content.hero.lienUrl ? content.hero.lienUrl[$route.meta.lang] : '#'" class="big-link link" data-inview="fadeInUp" data-delay="500">
                    <span>{{ contentIsLoaded ? content.hero.lien.customText : '&nbsp;' }}</span>
                    <svg
                        height="42"
                        viewBox="0 0 79 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M74.9733 26.0769L-0.000100322 26.0769L-0.000100228 23.9231L74.9733 23.9231L52.5295 1.50797L54.0324 -1.08907e-06L78.9473 25L54.0324 50L52.5295 48.5993L74.9733 26.0769Z"
                            fill="black"
                        />
                    </svg>
                </router-link>
            </div>
        </section>

        <!-- Condos -->
        <section id="condos" class="condos" condo-zoom>
            <div class="visual" condo-zoom-wrap data-inview="custom">
                <img
                    :src="contentIsLoaded ? content.condos.imageZoom.url : '/static-imgs/les-condos-hr3.jpg'"
                    :alt="contentIsLoaded ? content.condos.imageZoom.titre : ''"
                    condo-zoom-visual
                />

                <div class="content" condo-zoom-content>
                    <h3 class="small-title title">{{ contentIsLoaded ? content.condos.surtitre : '' }}</h3>
                    <h2 class="big-title text">
                        <span v-html="contentIsLoaded ? content.condos.titre : '&nbsp;'"></span>
                    </h2>
                </div>

                <span class="line" condo-zoom-line></span>
            </div>

            <div class="sub-content site-max-width">
                <div class="inner">
                    <h3 class="top-label label" data-inview="fadeInUp" data-delay="200">
                       {{ contentIsLoaded ? content.condos.sousTitre : '' }}
                    </h3>
                    <p class="big-text lefty-bloc-text" data-inview="fadeInUp" data-delay="400">
                        <span v-html="contentIsLoaded ? content.condos.texte : '&nbsp;'"></span>
                    </p>
                    <router-link :to="contentIsLoaded && content.condos.lienUrl ? content.condos.lienUrl[$route.meta.lang] : '#'" class="big-link link" data-inview="fadeInUp" data-delay="500">
                        <span>{{ contentIsLoaded ? content.condos.lien.customText : '' }}</span>
                        <svg
                            height="42"
                            viewBox="0 0 79 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M74.9733 26.0769L-0.000100322 26.0769L-0.000100228 23.9231L74.9733 23.9231L52.5295 1.50797L54.0324 -1.08907e-06L78.9473 25L54.0324 50L52.5295 48.5993L74.9733 26.0769Z"
                                fill="black"
                            />
                        </svg>
                    </router-link>

                    <SealDurable class="seal" condo-rotate />
                </div>
            </div>
        </section>

        <!-- Quartier -->
        <section id="quartier" class="quartier">
            <div class="grid-images-dynamic" quartier-zoomout>
                <img class="size" :src="contentIsLoaded ? content.quartierGrille.image1.url : '/static-imgs/quartier.jpg'" :alt="contentIsLoaded ? content.quartierGrille.image1.titre : ''" />

                <img
                    class="main"
                    :src="contentIsLoaded ? content.quartierGrille.image1.url : '/static-imgs/quartier.jpg'"
                    :alt="contentIsLoaded ? content.quartierGrille.image1.titre : ''"
                    quartier-zoomout-left
                />

                <img
                    class="secondary-top"
                    :src="contentIsLoaded ? content.quartierGrille.image2.url : '/static-imgs/cinema.jpg'"
                    :alt="contentIsLoaded ? content.quartierGrille.image2.titre : ''"
                    quartier-zoomout-top
                />
                <img
                    class="secondary-bottom"
                    :src="contentIsLoaded ? content.quartierGrille.image3.url : '/static-imgs/life.jpg'"
                    :alt="contentIsLoaded ? content.quartierGrille.image3.titre : ''"
                    quartier-zoomout-bottom
                />

                <img
                    class="last"
                    :src="contentIsLoaded ? content.quartierGrille.image4.url : '/static-imgs/velo.jpg'"
                    :alt="contentIsLoaded ? content.quartierGrille.image4.titre : ''"
                    quartier-zoomout-right
                />

                <!-- <h3 class="regular-title title" quartier-zoomout-title>
                    Lorem ipsum dolor <br />
                    sit amet consectetur, adipisicing elit.
                </h3> -->
            </div>
            <div class="site-max-width">
                <h3 class="top-label label" data-inview="fadeInUp" data-delay="200">
                    {{ contentIsLoaded ? content.quartierGrille.sousTitre : '' }}
                </h3>
                <p class="big-text lefty-bloc-text" data-inview="fadeInUp" data-delay="400">
                    {{ contentIsLoaded ? content.quartierGrille.texte : '' }}
                </p>
                <router-link :to="contentIsLoaded && content.quartierGrille.lienUrl ? content.quartierGrille.lienUrl[$route.meta.lang] : '#'" class="big-link link" data-inview="fadeInUp" data-delay="500">
                    <span>
                        {{ contentIsLoaded ? content.quartierGrille.lien.customText : '' }}
                    </span>
                    <svg
                        height="42"
                        viewBox="0 0 79 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M74.9733 26.0769L-0.000100322 26.0769L-0.000100228 23.9231L74.9733 23.9231L52.5295 1.50797L54.0324 -1.08907e-06L78.9473 25L54.0324 50L52.5295 48.5993L74.9733 26.0769Z"
                            fill="black"
                        />
                    </svg>
                </router-link>
            </div>
            <div class="lone-img site-max-width" data-inview="fadeInUp" data-delay="400">
                <img class="spacer" src="@/assets/img/map.jpg" alt="Map" />

                <img class="right" src="@/assets/img/map.jpg" alt="Map" />
            </div>
            <router-link :to="contentIsLoaded && content.quartierGrille.lienUrl ? content.quartierGrille.lienUrl[$route.meta.lang] : '#'" class="big-link link link-mobile" data-inview="fadeInUp" data-delay="500">
                <span>
                    {{ contentIsLoaded ? content.quartierGrille.lien.customText : '' }}
                </span>
                <svg height="42" viewBox="0 0 79 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M74.9733 26.0769L-0.000100322 26.0769L-0.000100228 23.9231L74.9733 23.9231L52.5295 1.50797L54.0324 -1.08907e-06L78.9473 25L54.0324 50L52.5295 48.5993L74.9733 26.0769Z"
                        fill="black"
                    />
                </svg>
            </router-link>
        </section>

        <!-- Form -->
        <BottomForm :formLink="globalsIsLoaded ? globals.footer.formulaireInscription.lienUrl[$route.meta.lang] : ''" :formTitle="$t('Découvrez <br /> le projet')" />
    </div>
</template>

<script>
// @ is an alias to /src
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

import { mapState, mapGetters, mapActions } from 'vuex'
import SealDurable from '@/components/svgs/SealDurable'
import BottomForm from '@/components/BottomForm'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Home',

    components: {
        SealDurable,
        BottomForm,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.startHomeHeroAnimations()
                        this.initZoomAnimations()

                        setTimeout(() => {
                            initInViewAnimations()
                            document.querySelector('#app').classList.add('is-loaded')
                        }, this.initAnimsDelay1)
                    }, 475)

                    setTimeout(() => {
                        document.querySelector('.site-loader').classList.add('is-loaded')
                        document.querySelector('.page-wrapper').classList.add('is-loaded')

                        this.setAnimsDelays(55)
                    }, this.initAnimsDelay2)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        ...mapActions(['setAnimsDelays']),
        smoothScrollTo(selector) {
            setTimeout(() => {
                gsap.to(window, { duration: 2, scrollTo: selector })
            }, 175)
        },
        startHomeHeroAnimations() {
            gsap.fromTo(
                '[hero-img-left]',
                {
                    y: '0',
                    x: '-15%',
                    opacity: 0,
                    scale: 0.95,
                },
                {
                    y: '0',
                    x: '0',
                    opacity: 1,
                    scale: 1,
                    duration: 1.7175,
                    delay: 1.5375,
                    ease: 'power2.inOut',
                }
            )

            gsap.fromTo(
                '[hero-img-top]',
                {
                    y: '-15%',
                    x: '0',
                    opacity: 0,
                    scale: 0.95,
                },
                {
                    y: '0',
                    x: '0',
                    opacity: 1,
                    scale: 1,
                    duration: 1.7175,
                    delay: 1.5375,
                    ease: 'power2.inOut',
                }
            )

            gsap.fromTo(
                '[hero-img-bottom]',
                {
                    y: '15%',
                    x: '0',
                    opacity: 0,
                    scale: 0.95,
                },
                {
                    y: '0',
                    x: '0',
                    opacity: 1,
                    scale: 1,
                    duration: 1.7175,
                    delay: 1.5375,
                    ease: 'power2.inOut',
                }
            )

            gsap.fromTo(
                '[condo-rotate]',
                {
                    rotate: 0,
                },
                {
                    rotation: 120,
                    duration: 48,
                    repeat: -1,
                    yoyo: true,
                    ease: 'none',
                }
            )

            gsap.fromTo(
                '[hero-img-right-mobile]',
                {
                    y: '0',
                    x: '25%',
                    opacity: 0,
                    scale: 1.26,
                },
                {
                    y: '0',
                    x: '0',
                    opacity: 1,
                    scale: 1,
                    duration: 1.7175,
                    delay: 1.9375,
                    ease: 'power2.inOut',
                }
            )

            gsap.fromTo(
                '[condo-rotate-mobile]',
                {
                    rotate: 0,
                },
                {
                    rotation: 120,
                    duration: 48,
                    repeat: -1,
                    yoyo: true,
                    ease: 'none',
                }
            )

            gsap.to('[hero-parallax-left]', {
                scrollTrigger: {
                    trigger: '.hero',
                    start: 'top 50%',
                    end: () => '+=300%',
                    scrub: 1.475,
                },
                y: '-25%',
                ease: 'none',
            })
        },
        initZoomAnimations() {
            // gsap.to('[condo-zoom-visual]', {
            //     scrollTrigger: {
            //         trigger: '[condo-zoom]',
            //         start: 'top top',
            //         end: () => '+=66%',
            //         scrub: 0.5,
            //         pin: true,
            //         anticipatePin: 0,
            //         onUpdate: self => {
            //             // console.log(
            //             //     "progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity()
            //             // );
            //             if (!document.querySelector('[condo-zoom-content]')) {
            //                 return
            //             }

            //             if (self.progress.toFixed(3) >= 0.8475) {
            //                 document
            //                     .querySelector('[condo-zoom-content]')
            //                     .classList.add('is-active')
            //                 document.querySelector('[condo-zoom-wrap]').classList.add('is-active')
            //                 document.querySelector('[condo-zoom-line]').classList.add('is-active')
            //             } else {
            //                 document
            //                     .querySelector('[condo-zoom-content]')
            //                     .classList.remove('is-active')
            //                 document
            //                     .querySelector('[condo-zoom-wrap]')
            //                     .classList.remove('is-active')
            //                 document
            //                     .querySelector('[condo-zoom-line]')
            //                     .classList.remove('is-active')
            //             }
            //         },
            //     },
            //     scale: 1,
            //     ease: 'none',
            // })

            // gsap.to('[quartier-zoomout]', {
            //     scrollTrigger: {
            //         trigger: '[quartier-zoomout]',
            //         start: 'top top',
            //         end: () => "+=55%",
            //         scrub: 0.5,
            //         pin: true,
            //         anticipatePin: 0,
            //     },
            //     ease: 'none',
            // });

            gsap.to('[quartier-zoomout-left]', {
                scrollTrigger: {
                    trigger: '[quartier-zoomout]',
                    start: 'top 75%',
                    end: () => '+=75%',
                    scrub: 0.5,
                },
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                ease: 'none',
            })

            gsap.to('[quartier-zoomout-top]', {
                scrollTrigger: {
                    trigger: '[quartier-zoomout]',
                    start: 'top 75%',
                    end: () => '+=75%',
                    scrub: 0.5,
                },
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                ease: 'none',
            })

            gsap.to('[quartier-zoomout-bottom]', {
                scrollTrigger: {
                    trigger: '[quartier-zoomout]',
                    start: 'top 75%',
                    end: () => '+=75%',
                    scrub: 0.5,
                },
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                ease: 'none',
            })

            gsap.to('[quartier-zoomout-right]', {
                scrollTrigger: {
                    trigger: '[quartier-zoomout]',
                    start: 'top 75%',
                    end: () => '+=75%',
                    scrub: 0.5,
                },
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                ease: 'none',
            })

            // gsap.to('[quartier-zoomout-title]', {
            //     scrollTrigger: {
            //         trigger: '[quartier-zoomout]',
            //         start: 'top 40%',
            //         end: () => "+=25%",
            //         scrub: 0.15,
            //     },
            //     opacity: 0,
            //     ease: 'none',
            // });
        },
    },
}
</script>
